import React, { CSSProperties } from 'react';

import Logo from '../assets/tk_solid_black_cut.svg';

interface Props {
  logoColor?: 'primary' | 'white',
  textColor?: 'primary' | 'white',
  bigger?: boolean,
  className?: string,
}

interface State {

}

const ColorToClassName = {
  'primary': 'text-primary',
  'white': 'text-white',
}

const Texts: { [id: string]: string } = {
  firstName: 'Tobias',
  lastName: 'Kächele'
}

const Styles: { [id: string]: { [size: string]: CSSProperties; }; } = {
  'nameText': {
    'normal': { fontFamily: 'Asap', fontSize: "0.9rem", lineHeight: "1em", fontWeight: 600 },
    'bigger': { fontFamily: 'Asap', fontSize: "1.1rem", lineHeight: "1.05em", fontWeight: 600 },
  },
  'logo': {
    'normal': { marginTop: "-0.07em", marginRight: "0.5em", height: "2.4rem" },
    'bigger': { marginTop: "-0.07em", marginRight: "0.5em", height: "3.0rem" },
  }
}


export default class Banner extends React.Component<Props, State> {
  render() {
    const logoClassNames = ColorToClassName[this.props.logoColor || 'primary']
    const textClassNames = ColorToClassName[this.props.textColor || 'primary']
    const size = this.props.bigger ? 'bigger' : 'normal'

    const additionalClassNames = this.props.className || ''

    return (
      <div className={`container-fluid d-flex align-items-center ${additionalClassNames}`}>
        <div className={logoClassNames}>
          <Logo style={Styles.logo[size]} />
        </div>
        <div className={textClassNames} style={Styles.nameText[size]}>
          {Texts.firstName}<br />
          {Texts.lastName}
        </div>
      </div>
    );
  }
}

